import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'
import { getFullCompany } from '../../../src/common/utils/getCompany'
import { locale } from '../../../src/common/i18n'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingEconomy = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    // Escrito de esta forma para que no se transforme en data.banners_mobile
    // que da error al comprobar tipos.
    data[`${'banners'}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`${'banners'}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Neumáticos Económicos" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
          En <strong>{getFullCompany()}</strong> trabajamos con las marcas de
          neumáticos baratos con la mejor relación de calidad y precio que
          existen en el mercado. Esto nos permite ofrecer{' '}
          <strong>
            los mejores precios de las Islas Canarias con una calidad de
            servicio excelente
          </strong>
          . Nuestras marcas de gama Económica son una excelente elección para
          aquellos conductores que buscan un neumático barato que cuente con el
          reconocimiento de profesionales del sector del neumático.
        </p>

        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />

        <section className={styles.section}>
          <h2>Gran selección de Neumáticos Económicos</h2>
          <p>
            ¿Buscas los mejores precios en neumáticos? En nuestra selección de{' '}
            <strong>Marcas Economy</strong> encontrarás marcas de{' '}
            <strong>neumáticos baratos</strong> especialmente escogidas por los
            técnicos especialistas de {getFullCompany()} para ofrecer a sus
            clientes la mejor calidad al mejor precio. Estas marcas son nuevas
            en el sector y cuentan con <strong>ofertas en neumáticos</strong>{' '}
            con <strong>precios imbatibles</strong> y una buena calidad en los
            materiales utilizados en su fabricación.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            Muchos de estos neumáticos son fabricados en países asiáticos, lo
            que permite que se puedan ofertar a precios mucho más reducidos que
            modelos de otras marcas. Gracias a la selección que realizamos en{' '}
            {getFullCompany()} te aseguras, además, estar adquiriendo un
            producto que ha sido{' '}
            <strong>
              verificado por profesionales especializados en el sector de
              neumático
            </strong>
            . Todos nuestros neumáticos de gama económica han superado las
            pruebas de calidad y eficiencias exigidas por la Unión Europea.
          </p>
        </section>
        <section className={styles.section}>
          <p>
            Algunas de nuestras marcas económicas más vendidas son Roadx, Rapid,
            Tigar, Nankang, Jinyu, Fortune, Fullway, Barkley, Apollo y Barum.
          </p>
        </section>
      </article>
    </div>
  )
}

export default LandingEconomy

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-economy'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'verano',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-economy')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)
