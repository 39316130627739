import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import { SeoData } from '../../../../src/common/types/Seo'
import { t } from '../../../../src/common/i18n'
import route from '../../../../src/common/utils/route'
import styles from '../../../../src/common/pages/landing.module.scss'
import LandingEconomy from '../../static/landing-economy'
import ListaMarcas from '../../../../src/common/components/landingNeumaticos/ListaMarcas'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos'),
        },
        {
          display: t('bread_crumb.economy'),
        },
      ]}>
      <LandingEconomy styles={styles} data={data} />
      <div className={styles.seccionMarcas}>
        <div className={styles.category_marcas}>
          <ListaMarcas type="BUDGET" />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query landingEconomyQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_desktop: allS3Asset(
      filter: { Key: { glob: "landings/type/economy/banner/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_mobile: allS3Asset(
      filter: { Key: { glob: "landings/type/economy/banner/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
